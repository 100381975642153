<template>
  <div>
    <b-modal v-model="show" centered hide-footer size="1200" title="Shartnomani tanlang">
      <!-- <b-tabs>
        <b-tab title="Shartnomalar" @click="onChange('contracts')" />
        <b-tab title="Eski shartnomalar" @click="onChange('oldContracts')" />
      </b-tabs> -->
      <b-form-input v-model="search" debounce="500" class="w-100 mb-1" placeholder="FIO bo'yicha izlash" />
      <vue-good-table :columns="columns" :rows="items[i]" :rtl="direction">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'row_number'">
            {{ props.index + 1 }}
          </span>
          <span v-if="props.column.field === 'fio'">
            <b-link  class="link" @click="$emit('selectedContract', props.row)">{{
              props.row.client.lastname +
              " " +
              props.row.client.firstname +
              " " +
              props.row.client.middlename
            }}</b-link>
            </span>
          <span v-else-if="props.column.field === 'overdue_amount'">
            <b-badge v-if="props.formattedRow.overdue_amount > 0" pill variant="light-danger">{{
              props.formattedRow.overdue_amount | formatPrice }}</b-badge>
            <span v-else>{{ props.formattedRow.overdue_amount }}</span>
          </span>
          <span v-else-if="props.column.field === 'credit_amount'">
            <p>{{ props.row.credit_amount | formatPrice }} so'm</p>
            <span>(<b>Oyiga: </b>
              {{
                (props.row.credit_amount / props.row.credit_months)
                | formatPrice
              }}) so'm</span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-modal>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import { mapActions } from 'vuex'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'SearchForPayment',
  components: { VueGoodTable },
  props: {
    // type: {
    //   type: String,
    //   default: 'contracts',
    // },
  },
  data() {
    return {
      search: null,
      items: [[]],
      show: false,
      type: 'contracts',
      i: 0,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Номер договора'),
          field: 'id',
        },
        {
          label: this.$t('ФИО'),
          field: 'fio',
        },
        {
          label: this.$t('Телефон'),
          field: 'client.phone',
        },
        {
          label: this.$t('Паспорт'),
          field: this.type == 'contracts' ? this.passportField : 'passport',
          filterField: 'passport',
        },
        {
          label: 'Продукты',
          field: 'product_names_str',
        },
        {
          label: 'Срок рассрочки (месяц)',
          field: 'credit_months',
        },
        {
          label: 'Сумма рассрочки',
          field: 'credit_amount',
          isAmount: true,
        },
        {
          label: this.$t('Просроченная сумма'),
          field: 'overdue_amount',
        },
      ]
    },
  },
  watch: {
    search(newVal) {
      if (newVal && newVal !== '') {
        if (this.type == 'contracts') {
          this.indexAll({
            search: newVal,
            relations: 'client',
            appends: 'product_names_str',
            status: 1,
            get_all:1,
            is_rejected: 0,
            per_page: 999,
          }).then(res => {
            this.i += 1
            this.items.push(res.data.data)
          })
        } else {
          this.getItemsAction({
            search: newVal,
            per_page: 999,
          }).then(res => {
            console.log(555, res.data.data)
            this.i += 1
            this.items.push(res.data.data)
          })
        }
      } else {
        this.items = [[]]
        this.i = 0
      }
    },
    show() {
      this.search = null
      this.items = [[]]
      this.i = 0
    },
    $route() {
      this.show = false
    },
  },
  methods: {
    ...mapActions({
      indexAll: 'contract/indexAll',
      getItemsAction: 'contract/getOldContracts',
    }),
    passportField(row) {
      return row.client.doc_series + row.client.doc_number
    },
    onChange(type) {
      this.type = type
      this.search = null
    },
  },
}
</script>

<style>
div.modal .modal-dialog.modal-1200 {
  max-width: 80% !important;
}
</style>
