<template>
  <div>
    <b-modal
      id="modal-lg"
      v-model="outerVisible"
      size="lg"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      class="candidatePageModal"
      width="646px"
      title="Buyurtma ma'lumotlari"
      top="5vh"
      append-to-body
      :before-close="handleClose"
    >

      <div v-if="outerVisible">
        <div v-if="orderInfo">
          <div
            v-if="orderInfo.is_showed === false"
            class="flag"
          />

          <div class="d-flex justify-content-between mb-2">
            <div>
              <div class="d-flex align-items-center mb-1">
                <span class="text-muted fs15 font-weight-bold mr-1">Mijoz FIO: </span>
                <div class="fs17  font-weight-bolder">
                  {{ orderInfo.full_name }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <span class="text-muted fs15 font-weight-bold mr-1">Telefoni: </span>
                <div class="fs15 text-success">
                  <span
                    v-if="orderInfo.phone"
                    class="font-weight-bolder"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="12"
                    />
                    +998 {{ formatPhoneNumber(orderInfo.phone) }}</span>

                </div>
              </div>

              <div class="d-flex align-items-center mb-1">
                <span class="text-muted fs15 font-weight-bold mr-1">Buyurma manbasi: </span>
                <div
                  v-if="orderInfo.source"
                  class="text-right"
                >
                  <b-badge
                    pill
                    :variant="orderInfo.source == 'telegram' ? 'info' : orderInfo.source == 'marketplace' ? 'warning' : 'primary'"
                  >
                    {{ orderInfo.source }}
                  </b-badge>
                </div>
              </div>

              <div
                v-if="orderInfo.doing_user"
                class="d-flex align-items-center mb-1"
              >
                <span class="text-muted fs15 font-weight-bold mr-1">Ijrochi: </span>
                <div class="text-right">
                  <b-badge pill>
                    {{ orderInfo.doing_user.name }}
                  </b-badge>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center mb-1">
                <span class="text-muted fs15 font-weight-bold mr-1">O'zgartirilgan sana: </span>
                <div
                  v-if="orderInfo.updated_at"
                  class="fs13 text-bold nowrap"
                >
                  {{ formatDateYmd(orderInfo.updated_at) }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <span class="text-muted fs15 font-weight-bold mr-1">Yaratilgan sana: </span>
                <div
                  v-if="orderInfo.updated_at"
                  class="fs13 text-bold nowrap"
                >
                  {{ formatDateYmd(orderInfo.created_at) }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <b class="mt-2">Buyurtma qilingan mahsulotlar: </b>
            <div
              v-if="ordersField(orderInfo).length"
              class="topHr"
            >
              <p
                v-for="(product, index) in ordersField(orderInfo)"
                :key="product.id"
              >
                <b
                  class="text-primary cursor-pointer"
                  @click="openModal(product.id)"
                >
                  {{ index + 1 }})
                  {{ product.name }}
                </b>
                <span class="text-muted fs15 font-weight-bold mr-1 ml-1">x <b class="text-dark">{{ product.quantity
                }}</b>
                  dona</span>
                <span class="text-muted fs15 font-weight-bold mr-1 ml-1"><b class="text-dark">{{ product.price |
                  formatPrice }}</b> so'm</span>
              </p>
            </div>
            <div v-else>
              Mahsulot mavjud emas
            </div>
            <div
              v-if="statusOrderId != 6"
              class="text-right"
            >
              <b-button
                variant="info"
                class="btn-tour-skip"
                @click="createUser"
              >
                <span class="w-100 align-middle">Shartnoma yaratish</span>
              </b-button>
            </div>
          </div>
          <hr>
          <div
            v-if="orderInfo.comment"
            class="mt-2"
          >
            <div class="d-flex justify-content-between">
              <h4>Izoh</h4>
              <span
                class="link"
                @click="openCommentForm = true"
              > <feather-icon
                icon="EditIcon"
                size="12"
              />
                Tahrirlash</span>
            </div>
            <div class="card bg-yellow p-2 font-weight-bold">
              {{ orderInfo.comment }}
            </div>
          </div>
          <b-form
            class="p-2"
            @submit.prevent
          >
            <b-form-group
              label="Buyurtma holati"
              label-for="order_id"
            >
              <b-form-select
                id="order_id"
                v-model="statusOrderId"
                name="name"
                value-field="status"
                text-field="name"
                placeholder="Holatni o'zgartirish"
                :options="stepsList"
                @change="changeOrderStatus(statusOrderId)"
              />
            </b-form-group>
            <b-form-group
              v-if="statusOrderId == 3"
              label="Kelish sanasi"
              label-for="coming_date"
            >
              <!-- <x-date-picker
                id="coming_date"
                v-model="coming_date"
                placeholder="Kelish sanasi"
                @input="changeOrderComingDate(coming_date)"
              /> -->
              <el-date-picker
                id="coming_date"
                v-model="coming_date"
                placeholder="Kelish sanasi"
                type="date"
                placement="bottom-start"
                format="dd.MM.yyyy"
                :disabled="disabled"
                :picker-options="{ firstDayOfWeek: 1 }"
                value-format="yyyy-MM-dd"
                @input="changeOrderComingDate(coming_date)"
              />
            </b-form-group>
            <div
              v-if="statusOrderId == 6"
              class="d-flex align-items-center gap-10"
            >
              <b-form-group
                label="Shartnoma raqami"
                label-for="contract_id"
              >
                <b-form-input
                  id="contract_id"
                  v-model="contract_id"
                  disabled
                  name="contract_id"
                  debounce="500"
                  placeholder="Shartnoma raqami ID"
                />
              </b-form-group>
              <span
                v-if="contract_id"
                class="link"
                @click="editContractId = true"
              > <feather-icon
                icon="EditIcon"
                size="12"
              />
                Tahrirlash</span>
              <b-button
                v-else-if="!contract_id || editContractId"
                variant="warning"
                class="btn-tour-skip"
                @click="showContractsModal"
              >
                <span class="w-100 align-middle">Shartnomani tanlash</span>
              </b-button>
            </div>
            <div
              v-if="openCommentForm"
              class="mt-2"
            >
              <b-form-group
                label="Izoh"
                label-for="internal-payment-note"
              >
                <b-form-textarea
                  id="internal-payment-note"
                  v-model="comment"
                  placeholder="Izoh"
                  rows="5"
                  trim
                />
              </b-form-group>
              <div class="d-flex justify-content-end mt-2">
                <b-button
                  variant="outline-primary"
                  class="mr-2"
                  type="submit"
                  @click="sendCommit"
                >
                  Izohni saqlash
                </b-button>
              </div>
            </div>

          </b-form>
          <hr>
          <div class="d-flex justify-content-end mt-2">
            <b-button
              variant="outline-primary"
              class="mr-2"
              type="submit"
              @click="saveAndClose"
            >
              Saqlash
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="emptyHeight"
      />
    </b-modal>
    <!-- Party modal -->
    <b-modal
      id="modal-lg"
      v-model="showPartyModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      @close="showPartyModal = false"
    >
      <div class="hello">
        <WarehouseProductInfo
          :p-category-id="pCategoryId"
          :is-component="true"
        />
      </div>
    </b-modal>
    <!-- Userr create modal -->

    <div v-if="showCreateUserModal">
      <ClientCreateModal
        :is-component="true"
        @close="closeClientCreateModal"
      />
    </div>
    <!--  -->
    <SelectContractModal
      ref="selectContractRef"
      @selectedContract="setContractId"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import SelectContractModal from '@/components/modals/SelectContract.vue'

export default {
  components: {
    WarehouseProductInfo: () => import('@/views/warehouse/products/warehouseProductInfo.vue'),
    ClientCreateModal: () => import('@/views/order/components/client_create.vue'),
    SelectContractModal,
  },
  props: {
    stepsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      outerVisible: false,
      clickClose: 0,
      timeoutClickClose: null,
      orderInfo: null,
      comment: '',
      openCommentForm: false,
      showCreateUserModal: false,
      showPartyModal: false,
      pCategoryId: null,
      statusOrderId: null,
      contract_id: null,
      coming_date: null,
      editContractId: false,
    }
  },
  computed: {
    showCandidateModal() {
      return !!this.$route.query.order_id
    },
  },
  watch: {
    showCandidateModal(val) {
      if (this.outerVisible != val) {
        this.outerVisible = val
        this.showAction()
      }
    },
    outerVisible(val) {
      if (!val) {
        this.changeQuery('order_id', null)
        this.$router.push({ name: this.$route.name, params: this.$route.params })
      }
    },
  },
  mounted() {
    this.outerVisible = this.showCandidateModal
    if (this.$route.query.order_id) {
      this.showAction()
    }
  },
  methods: {
    ...mapActions({
      qOrderShowAction: 'shop/qOrderShow',
      updateOrders: 'shop/qOrderUpdate',
    }),
    handleClose(done) {
      if (this.isMaskClick()) {
        if (this.clickClose > 0) {
          this.clickClose = 0
          done()
        } else {
          this.clickClose++
          this.setTimeoutClickClose()
        }
      } else {
        done()
      }
    },
    isMaskClick() {
      const { event } = window
      if (event instanceof PointerEvent) {
        return event.srcElement.classList.contains('b-modal__wrapper')
      }
      return false
    },
    setTimeoutClickClose() {
      this.timeoutClickClose = setTimeout(() => {
        this.clickClose = 0
      }, 2000)
    },
    ordersField(row) {
      if (row.products && row.products.length) {
        const newRow = row.products.map(el => ({
          quantity: el.quantity,
          name: (el.product && el.product.name_uz) ? el.product.name_uz : '',
          price: (el.product && el.product.price_dollar) ? (Number(el.product.price_dollar) * Number(this.dollarRate)) : '',
          id: el.category_id,
        }))
        return newRow
      }
      return []
    },
    sendCommit() {
      this.updateOrders({ id: this.orderInfo.id, comment: this.comment }).then(() => {
        this.showAction()
        this.openCommentForm = false
      })
    },
    changeOrderStatus(status) {
      if (status == 6) {
        showToast('warning', 'Tuzilgan shartnomani tanlang!')
      } 
      // else {

        this.updateOrders({ id: this.orderInfo.id, status }).then(() => {
          this.showAction()
        })
      // }
    },
    changeOrderComingDate(coming_date) {
      this.updateOrders({ id: this.orderInfo.id, coming_date }).then(() => {
        this.showAction()
      })
    },
    showAction() {
      this.qOrderShowAction({ relations: 'products|doingUser', id: this.$route.query.order_id }).then(res => {
        this.orderInfo = res.data
        this.comment = res.data.comment
        this.contract_id = res.data.contract_id
        this.coming_date = res.data.coming_date
        this.statusOrderId = res.data.status
        if (!res.data.comment) {
          this.openCommentForm = true
        }
      })
    },
    showContractsModal() {
      this.$refs.selectContractRef.show = true
    },
    setContractId(data) {
      this.contract_id = data.id
      this.updateOrders({ id: this.orderInfo.id, status: 6, contract_id: this.contract_id }).then(() => {
        this.showAction()
        this.$refs.selectContractRef.show = false
      })
    },
    createUser() {
      this.showCreateUserModal = true
    },
    openModal(id) {
      this.showPartyModal = true
      this.pCategoryId = id
    },
    closeClientCreateModal(data) {
      if (data.data && data.data.pin) {
        this.goTo('OrderCreate', '', { pin: data.data.pin })
      }
      this.showCreateUserModal = false
    },
    saveAndClose() {
      this.changeQuery('order_id', null)
      this.$emit('close')
      // this.$router.push({ name: this.$route.name, params: this.$route.params })
    },
  },
}
</script>
