<template>
  <div class="candidatesBoardsPage">
    <!--  -->
    <div class="row">
      <!-- <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Telegram xabarlar</span>
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Buyurtmalar</span>
          </template>
        </b-tab>
      </b-tabs> -->
      <!--  -->
      <div
        id="candidatesBoardsId"
        v-dragscroll:firstchilddrag
        class="candidatesBoards"
      >
        <draggable
          class=""
          :list="stepsList"
          handle=".candidatesBoardHead"
          group="steps"
          draggable=".card-draggable"
          @change="changeStepOrder"
        >
          <!-- Telegram chat -->
          <!-- <div v-if="tgMessagesList.length" v-loading="responseChatsLoading" class="candidatesBoard">
            <div class="candidatesBoardHead border-b-yellow">
              <div class="candidatesBoardTitle">
                Telegram xabarlar
              </div>
            </div>
            <div class="candidatesBoardBody">
              <div class="candidatesBoardEmpty">
                <span class="u-s-none">{{ $t('Bu etapda xabarlar mavjud emas') }}</span>
              </div>
              <draggable class="candidates-list" :list="tgMessagesList" :group="'candidates'">
                <TgChatCard v-for="(item, index) in tgMessagesList" :key="'ordersList' + index"
                  class="bg-yellow u-s-none" :item="item" @click="changeQuery('tg_chat_id', item.chat_id)" />

              </draggable>
            </div>
          </div> -->
          <!--  -->
          <template v-for="(step, index) in stepsList">
            <div
              v-if="step.type == 'response'"
              :key="index"
              v-loading="responsesLoading"
              class="candidatesBoard"
            >
              <div class="candidatesBoardHead border-b-yellow">
                <div class="candidatesBoardTitle">
                  {{ step.name }}
                  ({{ step.orders.length }})
                </div>
              </div>
              <div class="candidatesBoardBody">
                <div class="candidatesBoardEmpty">
                  <span class="u-s-none">{{ $t('Bu etapda buyurtmalar mavjud emas') }}</span>
                </div>
                <draggable
                  class="candidates-list"
                  :list="step.orders"
                  :group="'candidates'"
                  @start="startDragCard"
                  @end="endDragCard"
                  @change="changeStepFromCandidates($event, step)"
                >
                  <OrderCard
                    v-for="(item, index) in step.orders"
                    :key="'ordersList' + index"
                    class="bg-yellow u-s-none"
                    :item="item"
                    @click="changeQuery('order_id', item.id)"
                  />
                </draggable>
              </div>
            </div>

            <div
              v-else
              :key="index"
              class="candidatesBoard"
              :class="{ 'card-draggable': isDraggable(step) }"
            >
              <div class="candidatesBoardHead">
                <div class="candidatesBoardTitle">
                  <img
                    v-if="isDraggable(step)"
                    src="/images/icons/draggable-dots.svg"
                    width="20px"
                  >
                  {{ step.name }}
                  ({{ step.orders.length }})
                </div>
              </div>
              <div class="candidatesBoardBody">
                <div class="candidatesBoardEmpty">
                  <span class="u-s-none">{{ $t('Bu etapda buyurtmalar mavjud emas') }}</span>
                </div>
                <draggable
                  class="candidates-list"
                  :style="{ minHeight: `${minHeightList}px` }"
                  :list="step.orders"
                  :group="'candidates'"
                  @change="changeStepVacancy($event, step)"
                  @start="startDragCard"
                  @end="endDragCard"
                >
                  <OrderCard
                    v-for="(item, index) in step.orders"
                    :key="index"
                    class="u-s-none u-s-none"
                    :class="{ 'bg-red': step.status == 5, 'bg-green': step.status == 6 }"
                    :item="item"
                    @click="changeQuery('order_id', item.id)"
                  />
                </draggable>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <OrderShowModal v-if="$route.query.order_id" :stepsList="stepsList" @close="getList" />
      <!-- <TgChatShowModal @reloadChats="getTgMessagesList" /> -->
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import OrderCard from '@/components/OrderCard.vue'
import OrderShowModal from '@/components/modals/OrderShowModal.vue'
import TgChatShowModal from './telegram/chat.vue'
import TgChatCard from './telegram/TgChatCard.vue'

export default {
  directives: {
    dragscroll,
  },
  components: {
    draggable,
    OrderCard,
    OrderShowModal,
    TgChatCard,
    TgChatShowModal,
  },
  // mixins: [stepChangeCandidate],
  data() {
    return {
      autoselectionCount: 0,
      stepSettingsModalShow: false,
      autoSelectLoading: false,
      responsesLoading: false,
      responseChatsLoading: false,
      minHeight: 0,
      minHeightList: 114,
      ordersList: [],
      tgMessagesList: [],
      stepsList: [
        {
          name: 'Buyurtmalar',
          order: 0,
          type: 'response',
          status: 0,
          orders: [],
        },
        {
          name: 'Jarayonda',
          order: 0,
          type: 'poccess',
          status: 1,
          orders: [],
        },
        {
          name: 'Qayta aloqa',
          order: 0,
          type: 'recall',
          status: 2,
          orders: [],
        },
        {
          name: "Do'konga keladi",
          order: 0,
          type: 'recall',
          status: 3,
          orders: [],
        },
        {
          name: 'Bekor qildi',
          order: 0,
          type: 'recall',
          status: 5,
          orders: [],
        },
        {
          name: 'Shartnoma tuzildi',
          order: 0,
          type: 'recall',
          status: 6,
          orders: [],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
    }),
    paramId() {
      return this.$route.params.id
    },
    stepId() {
      return this.$route.query.step ? this.$route.query.step : (this.$route.query['step-setting'])
    },
  },
  watch: {
    paramId() {
      this.getList()
    },
  },
  mounted() {
    this.getList()
    // this.getTgMessagesList()
  },

  methods: {
    ...mapActions({
      getItemsAction: 'shop/qOrdersList',
      getTgMessages: 'shop/getChats',
      getDollarRates: 'resource/getDollarRates',
      updateOrders: 'shop/qOrderUpdate',
    }),
    changeStepVacancy(event, val) {
      if (event.added) {
        if (event.added.element.doing_by && (event.added.element.doing_by !== this.user.id)) {
          this.$message({
            message: 'Bu buyurtma sizga tegishli emas',
            type: 'error',
          })
          this.getList()
        } else {
          this.updateOrders({ id: event.added.element.id, status: val.status }).then(() => {
            this.getList()
          })
        }
      }
    },
    changeStepOrder(event) {
      if (event.moved) {
        const newOrders = []
        const { moved } = event
        const toUp = moved.newIndex < moved.oldIndex
        let index = 0
        let startIndex; let endIndex
        if (toUp) {
          startIndex = moved.newIndex
          endIndex = moved.oldIndex
        } else {
          startIndex = moved.oldIndex
          endIndex = moved.newIndex
        }
        if (startIndex > 0) {
          index = this.stepsList[startIndex - 1].order
          index++
        }
        this.stepsList.forEach((model, i) => {
          if (i >= startIndex) {
            if (index > endIndex && index > model.order) {
              newOrders.push({ id: model.id, order: index })
            } else {
              newOrders.push({ id: model.id, order: index })
            }
            index++
          }
        })
        // this.updateOrders({ vacancy_steps: newOrders })
      }
    },

    changeStepFromCandidates(event, val) {
      if (event.added) {
        this.$message({
          message: "Buyurtmani bu doskaga ko'chirish mumkin emas",
          type: 'error',
        })
        this.getList()
      }
    },
    getList() {
      this.responsesLoading = true
      this.getItemsAction({ per_page: 500, relations: 'products|doingUser' }).then(res => {
        this.ordersList = res.data.data
        this.stepsList = this.stepsList.map(el => ({
          name: el.name,
          order: el.order,
          type: el.type,
          status: el.status,
          orders: this.getStepOrders(res.data.data, el),
        }))
      }).finally(() => { this.responsesLoading = false })
    },
    getTgMessagesList() {
      this.responseChatsLoading = true
      this.getTgMessages({ per_page: 500 })
        .then(res => {
          this.tgMessagesList = res.data
        })
        .finally(() => { this.responseChatsLoading = false })
    },

    getStepOrders(orders, step) {
      const selfOrderds = orders.filter(el => el.doing_by == this.user.id)
      const filtereedOrders = step.doing_by ? selfOrderds : orders

      return filtereedOrders.filter(or => {
        if (this.isAdmin || this.isDeputy) {
          return (or.status == step.status)
        }
        if (or.doing_by) {
          return ((or.doing_by == this.user.id) && (or.status == step.status))
        }
        return (or.status == step.status)
      })
    },
    isDraggable(step) {
      return step.type != 'finished' && step.type != 'declined' && !(step.step && step.step.fixed)
    },
    startDragCard() {
      const element = document.getElementById('candidatesBoardsId')
      if (element) {
        this.minHeightList = element.offsetHeight - 69
      }
    },
    endDragCard() {
      this.minHeightList = 114
    },
  },
}
</script>
<style scoped>
.sh1-steps {
  background: #fff;
  flex: 0 0 298px;
  border-radius: 8px 0 0 8px;
}

.sh1-steps.alone {
  border-radius: 8px;
}

.sh1-candidates {
  background: #fff;
  flex: 0 0 318px;
  border-left: 1px solid #CCCCCC;
}

.sh1-pagecard {
  flex: auto;
  border-left: 1px solid #CCCCCC;
  border-radius: 0 8px 8px 0;
  overflow: hidden;
}

.sh1-setting-steps {
  flex: auto;
  padding-left: 20px;
}
</style>
